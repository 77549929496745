export interface AppConfiguration<TDeployCountry extends string = DeployCountry> {
  countries: Record<TDeployCountry, CountryConfiguration>;
  server: ServerConfiguration;
}

export interface DeployEnvConfiguration {
  country: CountryConfiguration;
  deploymentType: DeployEnv;
}

export interface CountryConfiguration {
  accountId: string;
  language: Language;
  phoneCode: number;
  isoCode: string;
  deployCountry: DeployCountry;
  envKey: CountryEnvKey;
  currency: CountryCurrency;
  currencySymbol: string;
  postback: PostbackConfiguration;
  validators: {
    phone: RegExp;
  };
  banks: {
    code: string;
    name: string;
    providerCode: string;
  }[];
}

export interface ServerConfiguration {
  baseURL: string;
}

export interface PostbackConfiguration {
  campaignId: string;
  siteUrl: string;
}

export enum CountryPhoneCode {
  TH = 66,
  PH = 63,
}

export enum DeployCountry {
  TH = "th",
  PH = "ph",
}

export enum Language {
  TH = "th",
  EN = "en",
}

export enum CountryEnvKey {
  TH = "TH",
  PH = "PH",
}

export enum CountryCurrency {
  TH = "THB",
  PH = "PHP",
}

export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}
