export enum AuthStatus {
  Authenticating = "authenticating",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
  PasswordExpired = "passwordExpired",
}

export interface BankAccount {
  bankAccNo: string;
  bankAccName: string;
  bankKey: string;
  bankName: string | null;
  bankIcon: string | null;
}

/**
 * User mode
 * - Guest: User is not logged in
 * - Member: User is logged in
 */
export enum UserMode {
  GUEST = "guest",
  MEMBER = "member",
}
