import { useCallbackRef } from "kz-ui-sdk";
import { useEffect, useState } from "react";

export interface UseDocumentVisibilityChangeOptions {
  cb?: (isVisible: boolean) => void;
  // Delay in milliseconds to wait before updating the visibility state
  delay?: number;
  delayType?: "both" | "invisible";
}

export function useDocumentVisibilityChange({
  cb,
  delay,
  delayType = "both",
}: UseDocumentVisibilityChangeOptions = {}) {
  const [isVisible, setIsVisible] = useState(document.visibilityState === "visible");

  const [delayedIsVisible, setDelayedIsVisible] = useState(isVisible);

  const cbRef = useCallbackRef(cb);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisibleCheck = document.visibilityState === "visible";
      setIsVisible(isVisibleCheck);
      cbRef(document.visibilityState === "visible");
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [cbRef]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (delay && delay > 0) {
      timeout = setTimeout(() => {
        setDelayedIsVisible(isVisible);
      }, delay || 0);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay, delayType, isVisible]);

  useEffect(() => {
    if (delay && delay > 0 && delayType === "invisible" && isVisible) {
      setDelayedIsVisible(isVisible);
    }
  }, [delay, delayType, isVisible]);

  if (delay && delay > 0 && delayType === "invisible" && isVisible) {
    return isVisible;
  }

  if (delay && delay > 0) {
    return delayedIsVisible;
  }

  return isVisible;
}
