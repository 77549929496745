import { UserMode } from "@types";
import useAuthSession from "./useAuthSession";

/**
 * Hook to get current user mode
 */
const useUserMode = () => {
  const { userMode } = useAuthSession();

  return { userMode, isGuest: userMode === UserMode.GUEST, isMember: userMode === UserMode.MEMBER };
};

export default useUserMode;
