import { IconDeposit, IconDoor, IconNavHome, IconNavPrizes, IconNavProfile, IconNavRefer } from "@assets";
import CoinHeartbeat from "@components/utils/CoinHeartbeat";
import { useDepositDrawer } from "@components/utils/DepositDrawer/hooks";
import MemberModeProtector from "@components/utils/MemberModeProtector";
import { openOnboardingDrawer } from "@components/utils/OnboardingDrawer/utils";
import { OnboardMode, Paths } from "@constants";
import usePromotionStatus from "@hooks/usePromotionStatus";
import { UserMode } from "@types";
import { getThemeAsset } from "@utils/theme";
import {
  BottomNavigationAction,
  BottomNavigation as BottomNavigationUIKit,
  PropsWithClassName,
  WarningWrapper,
  cn,
} from "kz-ui-sdk";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface BottomNavigationProps extends PropsWithChildren, PropsWithClassName {
  userMode: UserMode;
}

const BottomNavigation = ({ className, userMode }: BottomNavigationProps) => {
  const { t } = useTranslation();
  const { openDepositDrawer } = useDepositDrawer();

  const { isAnyClaimable: isPromotionsClaimable, isReferralClaimable } = usePromotionStatus();

  const handleCenterButtonClick = () => {
    if (userMode === UserMode.MEMBER) {
      openDepositDrawer();
    } else {
      openOnboardingDrawer({
        mode: OnboardMode.REGISTER,
      });
    }
  };

  return (
    <>
      <BottomNavigationUIKit className={cn(className)}>
        {/*HOME*/}
        <NavLink
          to={Paths.PRIVATE.HOME}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <BottomNavigationAction
              icon={
                <IconNavHome
                  className={isActive ? "text-content-base" : "text-content-soft"}
                  width={20}
                  height={20}
                />
              }
              label={t("Home")}
              active={isActive}
              className="w-full"
            />
          )}
        </NavLink>

        {/*PRIZES*/}
        <NavLink
          to={Paths.PRIVATE.PRIZES}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <>
              <BottomNavigationAction
                icon={
                  <WarningWrapper
                    isShown={isPromotionsClaimable ?? false}
                    classes={{
                      "span&": "right-[-4px] top-[-2px] !h-[16px] !w-[16px] !text-[10px] border-2 border-[#291c5a]",
                    }}
                    icon={
                      <CoinHeartbeat
                        className="absolute -right-1 -top-1"
                        imgClassName="h-[13px] w-[13px]"
                      />
                    }
                  >
                    <IconNavPrizes
                      width={20}
                      height={20}
                      className={isActive ? "text-content-base" : "text-content-soft"}
                    />
                  </WarningWrapper>
                }
                label={t("Prizes")}
                className="h-[56px] w-[56px]"
                active={isActive}
              />
            </>
          )}
        </NavLink>

        {/*CENTER BUTTON*/}
        <button
          onClick={handleCenterButtonClick}
          className="group inline-block h-[60px] w-[60px] translate-y-[-10px]"
        >
          <div
            className={cn(
              "relative h-full w-full rounded-full bg-bottomNavBtnRound shadow-[0px_4px_6px_0px_rgba(0,_0,_0,_0.24)] transition-transform will-change-transform active:translate-y-0.5",
            )}
          >
            {/*COLOR MIST BG*/}
            <div
              className="absolute left-0 top-0 h-full w-full animate-spin rounded-full will-change-transform [animation-duration:16s]"
              style={{
                backgroundImage: `url(${getThemeAsset("misc/bg-nav-center.png")})`,
              }}
            />

            {/*ICON & TEXT*/}
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
              {/*DEPOSIT*/}
              {userMode === UserMode.MEMBER && (
                <>
                  <IconDeposit
                    width={20}
                    height={20}
                    className="text-content-base"
                  />
                  <span className="text-xs font-bold text-content-base">{t("Deposit")}</span>
                </>
              )}
              {/*REGISTER*/}
              {userMode === UserMode.GUEST && (
                <>
                  <IconDoor
                    width={20}
                    height={20}
                    className="text-content-base"
                  />
                  <span className="text-xs font-bold text-content-base">{t("nav register")}</span>
                </>
              )}
            </div>

            {/*RIM LIGHT*/}
            <div
              className={`absolute left-0 top-0 h-full w-full rounded-full border-0 border-dashed border-[rgba(0,0,0,0.20)] bg-bottomNavBtnRoundRimLight shadow-[inset_0px_2px_1px_0px_rgba(255,255,255,0.24),_inset_0px_4px_4px_0px_rgba(255,255,255,0.25)]`}
            />
            {/*GRAY OVERLAY*/}
            <div className={`absolute left-0 top-0 h-full w-full rounded-full bg-[rgba(217,217,217,0.01)]`} />
          </div>
        </button>

        {/*REFER*/}
        <NavLink
          to={Paths.PRIVATE.REFERRAL}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <>
              <BottomNavigationAction
                icon={
                  <WarningWrapper
                    isShown={isReferralClaimable ?? false}
                    classes={{
                      "span&": "right-[-4px] top-[-2px] !h-[16px] !w-[16px] !text-[10px] border-2 border-[#291c5a]",
                    }}
                    icon={
                      <CoinHeartbeat
                        className="absolute -right-1 -top-1"
                        imgClassName="h-[13px] w-[13px]"
                      />
                    }
                  >
                    <IconNavRefer
                      width={20}
                      height={20}
                      className={isActive ? "text-content-base" : "text-content-soft"}
                    />
                  </WarningWrapper>
                }
                label={t("Refer")}
                className="h-[56px] w-[56px]"
                active={isActive}
              />
            </>
          )}
        </NavLink>

        {/*PROFILE*/}
        <MemberModeProtector>
          <NavLink
            to={Paths.PRIVATE.PROFILE}
            className="h-[56px] w-[56px] flex-shrink-0"
          >
            {({ isActive }) => (
              <BottomNavigationAction
                icon={
                  <IconNavProfile
                    width={22}
                    height={22}
                    className={isActive ? "text-content-base" : "text-content-soft"}
                  />
                }
                label={t("Profile")}
                active={isActive}
                className="w-full"
              />
            )}
          </NavLink>
        </MemberModeProtector>
      </BottomNavigationUIKit>
    </>
  );
};

export default BottomNavigation;
