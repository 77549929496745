import { useEffect, useRef } from "react";

export function useEventListener<T extends Event>(options: {
  eventName: string;
  element?: EventTarget;
  handler?: (event: T) => void;
}) {
  const { eventName, element = window, handler } = options;

  const savedHandler = useRef<(event: T) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener: EventListener = (event) => {
      if (savedHandler.current) {
        savedHandler.current(event as T);
      }
    };

    if (element && element.addEventListener) {
      element.addEventListener(eventName, eventListener);
    }

    return () => {
      if (element && element.removeEventListener) {
        element.removeEventListener(eventName, eventListener);
      }
    };
  }, [eventName, element]);
}
