import { STORAGE_KEY } from "@constants";
import { useEffect } from "react";

/**
 * Component that detects if a new version of the app is available
 * @constructor
 */
const AppUpdateDetector = () => {
  useEffect(() => {
    if (!navigator.serviceWorker) {
      return;
    }

    const handleNewVersion = (event: MessageEvent) => {
      if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
        sessionStorage.setItem(STORAGE_KEY.APP_UPDATE_AVAILABLE, "true");
      }
    };

    navigator.serviceWorker.addEventListener("message", handleNewVersion);

    return () => {
      navigator.serviceWorker.removeEventListener("message", handleNewVersion);
    };
  }, []);

  return null;
};

export default AppUpdateDetector;
