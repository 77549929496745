import useAuthSession from "@hooks/useAuthSession";
import { UserMode } from "@types";
import { PropsWithChildren } from "react";

interface UserModeRendererProps extends PropsWithChildren {
  mode: UserMode;
}

/**
 * Allow to render the children component based on user mode.
 * @param props
 * @constructor
 */
const UserModeRenderer = ({ mode, children }: UserModeRendererProps) => {
  const { userMode, status } = useAuthSession();

  if (status === "authenticating") return null;

  if (userMode !== mode) return null;

  return <>{children}</>;
};

export default UserModeRenderer;
