import { openBankAccountDrawer } from "@components/utils/OnboardingDrawer/utils";
import useAuthSession from "@hooks/useAuthSession";
import { useCallback } from "react";
import { closeDeposit, openDeposit } from "../utils";

/**
 * Control the state of the deposit drawer with bank account verification
 */
export const useDepositDrawer = () => {
  const { bankAccount } = useAuthSession();

  const openDepositDrawer = useCallback(() => {
    // if user has bank account, open deposit drawer
    if (!!bankAccount) {
      openDeposit();
      return;
    }
    // else open bank account form
    openBankAccountDrawer(() => {
      // open deposit drawer after bank account form is completed
      openDeposit();
    });
  }, [bankAccount]);

  const closeDepositDrawer = useCallback(() => {
    closeDeposit();
  }, []);

  return {
    openDepositDrawer,
    closeDepositDrawer,
  };
};
