import reactHotToast, { ToastOptions } from "react-hot-toast";
import { MdInfoOutline } from "react-icons/md";

const toast = {
  ...reactHotToast,
  info: (message: string, options?: ToastOptions) =>
    reactHotToast(message, {
      icon: <MdInfoOutline className="h-5 w-5 text-informative-600" />,
      ...options,
    }),
};

export default toast;
