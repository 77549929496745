import { useAppUpdate } from "@hooks/useAppUpdate";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component that updates the app on routing
 * @constructor
 */
const AppUpdateOnRouting = () => {
  const location = useLocation();
  const { performAppReload } = useAppUpdate();

  useEffect(() => {
    // Check for app update on routing
    performAppReload();
  }, [location, performAppReload]);

  return null;
};

export default AppUpdateOnRouting;
