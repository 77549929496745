import { publicApi } from "@api";
import AppInstaller from "@components/utils/AppInstaller";
import AppVersion from "@components/utils/AppVersion";
import { deployEnvConfig } from "@configs/deploy-env.ts";
import { Paths, SEARCH_PARAMS } from "@constants";
import { storeReferralCode } from "@utils/referral";
import { PageTransitionOverlay, cn } from "kz-ui-sdk";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface RootRouteLayoutProps extends PropsWithChildren {}

const PRIVATE_PATHS = Object.values(Paths.PRIVATE);

const RootRouteLayout = ({ children }: RootRouteLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: serverConfig } = publicApi.useGetServerConfigQuery(
    {
      group: import.meta.env.VITE_BRAND_GROUP,
      accountId: deployEnvConfig.country.accountId,
      env: import.meta.env.VITE_DEPLOY_ENV,
    },
    {
      skip: import.meta.env.VITE_DEPLOY_ENV === "local",
    },
  );

  const overlayClassName = useMemo(() => {
    if (PRIVATE_PATHS.includes(location.pathname)) {
      return "z-[5]";
    }
    return "z-[1030]";
  }, [location.pathname]);

  const underMaintenance = useMemo(
    () => serverConfig?.underMaintenance ?? import.meta.env.VITE_MAINTENANCE === "true",
    [serverConfig],
  );

  //Redirect to maintenance if server config is maintenance
  useEffect(() => {
    if (underMaintenance) {
      navigate(Paths.PUBLIC.MAINTENANCE, {
        replace: true,
      });
    }
  }, [underMaintenance, navigate, serverConfig]);

  // Capture referral code from any URL
  useEffect(() => {
    const refCode = searchParams.get(SEARCH_PARAMS.REF_CODE);
    storeReferralCode(refCode);
  }, [searchParams]);

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // if ((isFetching || underMaintenance) && !matchPath(Paths.PUBLIC.MAINTENANCE, location.pathname)) return null;

  return (
    <>
      <div className={cn("w-screen max-w-[100vw]")}>
        <AppInstaller />
        <AppVersion />
        <PageTransitionOverlay
          className={cn(overlayClassName)}
          key={location.pathname}
        />
        {children}
      </div>
    </>
  );
};

export default RootRouteLayout;
