import { cn } from "kz-ui-sdk";
import { forwardRef, useMemo } from "react";
import "./CoinHeartbeat.css";

interface CoinHeartbeatProps {
  className?: string;
  imgSrc?: string;
  imgClassName?: string;
}

const CoinHeartbeat = forwardRef<HTMLDivElement, CoinHeartbeatProps>(function CoinHeartbeat(
  { className, imgSrc = "/images/icon-coin.png", imgClassName },
  ref,
) {
  // sync with machine clock, delay animation until next animation frame
  const delayAnimation = useMemo(() => {
    const animationDuration = 1.5;
    const now = new Date();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();
    return String(-(seconds + milliseconds / 1000) % animationDuration) + "s";
  }, []);

  return (
    <div
      ref={ref}
      className={cn("coin-heartbeat", className)}
    >
      <div
        className="coin-heartbeat__ring"
        style={{
          animationDelay: delayAnimation,
        }}
      />
      <img
        className={cn("coin-heartbeat__image", imgClassName)}
        src={imgSrc}
        alt="coin"
        style={{
          animationDelay: delayAnimation,
        }}
      />
    </div>
  );
});

export default CoinHeartbeat;
