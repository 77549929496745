import { OnboardMode } from "@constants";
import { EVENT_CLOSE_ONBOARDING, EVENT_OPEN_ONBOARDING, OnboardingStep } from "../constants";

export type OnboardingDrawerOption = {
  mode?: OnboardMode;
  step?: OnboardingStep;
  callback?: () => void;
};

export function openOnboardingDrawer(option: OnboardingDrawerOption) {
  window.dispatchEvent(
    new CustomEvent(EVENT_OPEN_ONBOARDING, {
      detail: option,
    }),
  );
}

export function openBankAccountDrawer(onUpdateSuccess?: () => void) {
  window.dispatchEvent(
    new CustomEvent(EVENT_OPEN_ONBOARDING, {
      detail: {
        step: OnboardingStep.BankAccountForm,
        callback: onUpdateSuccess,
      },
    }),
  );
}

export function closeOnboardingDrawer() {
  window.dispatchEvent(new CustomEvent(EVENT_CLOSE_ONBOARDING));
}
