import { authApi, publicApi, siteApi } from "@api";
import { Paths } from "@constants/paths";
import { useAppUpdate } from "@hooks/useAppUpdate.ts";
import { RootState } from "@stores";
import auth from "@stores/auth";
import { AuthSliceState } from "@stores/auth/slices.ts";
import { PreferenceSliceState } from "@stores/preference/slices.ts";
import { AuthStatus, BankAccount, UserMode } from "@types";
import logger from "@utils/logger";
import { GroupCriteriaType } from "kz-ui-sdk";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * Get the current auth session.
 * return { self, oauth, status, bankAccount, logout, bankInfos, userMode }
 * - self: Current user profile
 * - oauth: Current auth token
 * - status: Current auth status
 * - bankAccount: Current user bank account
 * - logout: Logout function
 * - bankInfos: All bank information
 * - userMode: Current user mode, use for UI display
 */
const useAuthSession = () => {
  const { self, oauth, bankInfos, latestDepositAmount } = useSelector<RootState, AuthSliceState>((state) => state.auth);
  const { serverInfo } = useSelector<RootState, PreferenceSliceState>((state) => state.preferences);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { performAppReload } = useAppUpdate();

  const status = useMemo(() => {
    // Token not found, authenticating state
    if (oauth === undefined) {
      logger._console.log("[Auth] Token not found, authenticating...");
      return AuthStatus.Authenticating;
    }
    // Found token, but no profile, unauthenticated state
    // Can be used to redirect to login page
    if (!self || !oauth) {
      logger._console.log("[Auth] Token found, but no profile, Unauthenticated.", { self }, { oauth });
      return AuthStatus.Unauthenticated;
    }
    // Found token and profile, authenticated state
    logger._console.log("[Auth] Token and profile found, Authenticated.", { self }, { oauth });
    // Check if user needs to reset password
    if (self?.member?.pwExpireAt) {
      logger._console.log("[Auth] Found pwExpiredAt.", { self }, { oauth });
      const expiredAt = moment.utc(self?.member?.pwExpireAt).utcOffset(serverInfo?.utcOffset ?? 0);
      logger._console.log("[Auth] Found pwExpiredAt.", expiredAt.format(), moment().format());
      if (expiredAt.isBefore(moment())) {
        return AuthStatus.PasswordExpired;
      }
    }
    return AuthStatus.Authenticated;
  }, [oauth, self, serverInfo?.utcOffset]);

  const logout = useCallback(() => {
    // clear redux store
    dispatch(auth.slice.actions.logout());
    // reset api cache
    dispatch(siteApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(publicApi.util.resetApiState());
    // redirect to home page (open mode)
    navigate(Paths.PRIVATE.HOME);
  }, [navigate, dispatch]);

  const bankAccount: BankAccount | null = useMemo(() => {
    if (self?.member?.bankAccNo) {
      return {
        bankAccNo: self.member.bankAccNo,
        bankAccName: self.member.fullname,
        bankKey: self.member.bankKey,
        bankName: !!self.member.bankKey ? bankInfos?.[self.member.bankKey]?.name : null,
        bankIcon: !!self.member.bankKey ? bankInfos?.[self.member.bankKey]?.icon : null,
      } as BankAccount;
    }
    return null;
  }, [self?.member?.bankAccNo, self?.member?.fullname, self?.member?.bankKey, bankInfos]);

  const isBlacklisted = useMemo(() => {
    return self?.member?.group?.group?.criteria?.type === GroupCriteriaType.Blacklist;
  }, [self]);

  const userMode = useMemo(() => {
    return status === AuthStatus.Authenticated ? UserMode.MEMBER : UserMode.GUEST;
  }, [status]);

  // Reload after login & has bank account
  useEffect(() => {
    if (status === AuthStatus.Authenticated && !!bankAccount) {
      // Reload app
      performAppReload();
    }
  }, [status, performAppReload, bankAccount]);

  return { self, oauth, status, bankAccount, logout, bankInfos, userMode, latestDepositAmount, isBlacklisted };
};

export default useAuthSession;
