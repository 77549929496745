import { getBrandResource } from "@utils/misc";
import { cn, PropsWithClassName } from "kz-ui-sdk";
import { useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

type BrandLogoType = "sm" | "md" | "auto";
interface BrandLogoProps extends PropsWithClassName {
  size?: BrandLogoType;
  navigateTo?: string;
  navigateOptions?: NavigateOptions;
  style?: React.CSSProperties;
}

const BrandLogo = ({ size = "md", className, navigateTo, navigateOptions, style }: BrandLogoProps) => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo, navigateOptions);
    }
  };

  if (isError) {
    return (
      <span
        className={cn(
          "no-tap-highlight flex items-center justify-center object-contain text-center text-content-base",
          SIZE_MAP_CLASSES[size],
          PLACEHOLDER_MAP_CLASSES[size],
          className,
        )}
      >
        {import.meta.env.VITE_BRAND_NAME}
      </span>
    );
  }

  return (
    <img
      src={getBrandResource("logo.png")}
      className={cn("no-tap-highlight object-contain", SIZE_MAP_CLASSES[size], className)}
      alt={"logo"}
      onClick={handleClick}
      role={navigateTo ? "button" : undefined}
      onError={handleError}
      style={style}
    />
  );
};

const SIZE_MAP_CLASSES: Record<BrandLogoType, string> = {
  sm: "h-[46px] w-auto",
  md: "h-[32px] w-auto",
  auto: "w-auto h-full",
};

const PLACEHOLDER_MAP_CLASSES: Record<BrandLogoType, string> = {
  sm: "h-[46px]",
  md: "h-[40px] text-2xl",
  auto: "h-full",
};

export default BrandLogo;
