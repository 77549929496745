import logger from "@utils/logger";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface UseWebSocketReturn<T = unknown> {
  messages: string[];
  error: Event | null;
  isConnected: boolean;
  sendMessage: (message: string) => void;
  lastJsonMessage?: T;
}

const DEFAULT_HEARTBEAT = {
  message: "ping",
  returnMessage: "pong",
  timeout: 60000,
  interval: 25000,
};

export interface WebSocketHeartbeatOptions {
  message: "ping" | "pong" | string | (() => string);
  returnMessage: "ping" | "pong" | string;
  timeout: number;
  interval: number;
}

export interface UseWebSocketManualOptions {
  url?: string;
  maxRetries?: number;
  retryDelay?: number;
  heartbeat?: boolean | WebSocketHeartbeatOptions;
}

export const useWebSocketManual = <T = unknown>(options: UseWebSocketManualOptions): UseWebSocketReturn<T> => {
  const { url, maxRetries = 5, retryDelay = 5000, heartbeat } = options;
  const [messages, setMessages] = useState<string[]>([]);
  const [error, setError] = useState<Event | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const socketRef = useRef<WebSocket | null>(null);
  const retryCount = useRef<number>(0);
  const lastHeartbeat = useRef<number>(Date.now());

  const heartbeatOptions = useMemo(() => {
    if (typeof heartbeat === "boolean") {
      return heartbeat ? DEFAULT_HEARTBEAT : undefined;
    }
    return { ...DEFAULT_HEARTBEAT, ...heartbeat };
  }, [heartbeat]);

  const createConnection = useCallback(
    (url: string) => {
      retryCount.current = 0;
      const socket = new WebSocket(url);
      socketRef.current = socket;

      socket.onopen = () => {
        logger._console.debug("[WS] connected");
        setIsConnected(true);
        setError(null);
        retryCount.current = 0;
      };

      socket.onmessage = (event) => {
        setMessages((prevMessages) => [...prevMessages, event.data]);

        if (heartbeatOptions && event.data === heartbeatOptions.returnMessage) {
          logger._console.debug("[WS] heartbeat received");
          lastHeartbeat.current = Date.now();
          return;
        }
      };

      socket.onerror = (event) => {
        setError(event);
        logger._console.debug("[WS] error:", event);
        if (retryCount.current < maxRetries) {
          setTimeout(() => {
            if (socketRef.current !== socket) {
              socket.close();
              return;
            }
            logger._console.debug("[WS] Reconnecting...");
            retryCount.current++;
            socketRef.current?.close();
            createConnection(url);
          }, retryDelay);
        }
      };

      socket.onclose = () => {
        setIsConnected(false);
        logger._console.debug("[WS] WebSocket closed");
        if (retryCount.current < maxRetries) {
          setTimeout(() => {
            if (socketRef.current !== socket) {
              socket.close();
              return;
            }
            logger._console.debug("[WS] Reconnecting...");
            retryCount.current++;
            socketRef.current?.close();
            createConnection(url);
          }, retryDelay);
        }
      };
    },
    [heartbeatOptions, maxRetries, retryDelay],
  );

  useEffect(() => {
    socketRef.current?.close();
    socketRef.current = null;
    setError(null);

    if (url) {
      createConnection(url);
    }

    return () => {
      socketRef.current?.close();
      socketRef.current = null;
      setMessages([]);
      setError(null);
      setIsConnected(false);
    };
  }, [url, createConnection]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (heartbeatOptions && isConnected) {
      interval = setInterval(() => {
        if (socketRef.current && isConnected) {
          const diff = Date.now() - lastHeartbeat.current;

          if (diff > heartbeatOptions.timeout) {
            logger._console.error("[WS] Heartbeat timeout");
            socketRef.current.close();
          } else {
            logger._console.debug("[WS] Sending heartbeat");
            const message =
              typeof heartbeatOptions.message === "function" ? heartbeatOptions.message() : heartbeatOptions.message;

            socketRef.current.send(message);
          }
        }
      }, heartbeatOptions.interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [heartbeatOptions, isConnected]);

  const sendMessage = useCallback(
    (message: string) => {
      if (socketRef.current && isConnected) {
        socketRef.current.send(message);
      } else {
        logger._console.error("[WS] WebSocket is not connected");
      }
    },
    [isConnected],
  );

  const lastJsonMessage = useMemo(() => {
    try {
      if (messages.length === 0) {
        return undefined;
      }
      return JSON.parse(messages[messages.length - 1]) as T;
    } catch (error) {
      logger._console.error("[WS] Failed to parse last message as JSON:", error);
      return undefined;
    }
  }, [messages]);

  return { messages, error, isConnected, sendMessage, lastJsonMessage };
};
