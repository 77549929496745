import { authApi } from "@api/index";
import { RootState, store } from "@stores/index";
import { getTokenExpirationDate } from "@utils/jwt";
import { AuthToken } from "kz-ui-sdk";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export function useTokenWatcher() {
  const auth = useSelector<RootState, AuthToken | null | undefined>((state) => state?.auth?.oauth);

  const [getProfile] = authApi.useLazyGetProfileQuery();

  useEffect(() => {
    const currentToken = auth?.access_token;
    let timeOut: NodeJS.Timeout;

    if (currentToken) {
      const timeExp = getTokenExpirationDate(currentToken);
      const now = new Date();

      if (timeExp && timeExp.toDate().getTime() - now.getTime() > 0) {
        const diff = timeExp.toDate().getTime() - now.getTime();

        timeOut = setTimeout(async () => {
          const state = store.getState() as RootState;
          if (state.auth.isRefreshingToken) {
            return;
          }
          // Just trigger getProfile to refresh token
          await getProfile();
        }, diff + 500);
      }
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [auth, getProfile]);
}
