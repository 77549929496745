import AppUpdateDetector from "@components/utils/AppUpdateDetector";
import { ThemePreset } from "@configs/theme-preset";
import { store } from "@stores";
import "@styles/App.css";
import { ThemeProvider } from "kz-ui-sdk";
import "react-aspect-ratio/aspect-ratio.css";
import { Provider } from "react-redux";
import AppRoutes from "./AppRouter";
import "./configs/i18n";

function App() {
  return (
    <ThemeProvider
      value={{
        preset: ThemePreset,
      }}
    >
      <Provider store={store}>
        <AppRoutes />
        <AppUpdateDetector />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
