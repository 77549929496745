import { useAppWsEvent } from "@hooks/useAppWS";
import useDepositNotification from "@hooks/useDepositNotification";
import { useTokenWatcher } from "@hooks/useTokenWatcher";
import { Outlet } from "react-router-dom";

/**
 * Websocket route layout
 * Which is used to connect to websocket
 */
const WSRouteLayout = () => {
  useTokenWatcher();
  useAppWsEvent();
  useDepositNotification();

  return <Outlet />;
};

export default WSRouteLayout;
