import * as Sentry from "@sentry/react";
import { isProd } from "@utils/deployEnv";
import { MemberModel } from "kz-ui-sdk";
import moment from "moment";

const getAppVersion = async () => {
  const response = await fetch(import.meta.env.VITE_PUBLIC_URL + "/version.json");
  const { version } = await response.json();
  return moment(parseInt(version + "000")).format("vYYMMDDHHmm");
};

/**
 * Initialize Sentry
 * @description Initialize Sentry for error tracking
 * Only initialize in production
 */
export const initSentry = async () => {
  try {
    // Enable only in production
    if (isProd()) {
      const version = (await getAppVersion()) ?? moment().format("vYYMMDDHHmm");
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_BRAND_KEY,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.captureConsoleIntegration({
            levels: ["error"],
          }),
        ],
        tracesSampleRate: 1.0, // Capture % of the transactions
        release: version,
        /**
         * Ignore some safe errors
         */
        ignoreErrors: [
          // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/4
          "ResizeObserver loop limit exceeded",
          // https://stackoverflow.com/questions/59074487/reference-error-cant-find-variable-intersectionobserver
          "Can't find variable: IntersectionObserver",
          // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
          "Non-Error promise rejection captured",
          "Non-Error exception captured",
          // In case app loads old service worker
          /TypeError: Script https?:\/\/[^/]+\/service-worker\.js load failed/,
        ],
        beforeSend(event) {
          // Ignore error from 3rd party/extension/game frame
          const frames = event?.exception?.values?.[0]?.stacktrace?.frames;
          const firstFrame = frames?.[0];
          if (firstFrame && firstFrame.filename === "<anonymous>") {
            return null;
          }

          return event;
        },
      });
      Sentry.setContext("Dependencies", {
        "kz-ui-sdk": import.meta.env.KZ_UI_SDK_VERSION || "unknown",
      });
      return Promise.resolve();
    }
  } catch (error) {
    console.error("Sentry initialization failed:", error);
  }
};

/**
 * This member will be sent to Sentry reporting
 */
export const setSentryMember = (member?: MemberModel | null) => {
  if (isProd()) {
    if (!member) {
      // Clear user
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({
      id: member.id,
      username: member.phone + "-" + member.apiIdentifier,
    });
  }
};
