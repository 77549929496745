import { openOnboardingDrawer } from "@components/utils/OnboardingDrawer/utils";
import { OnboardMode } from "@constants";
import useAuthSession from "@hooks/useAuthSession";
import { UserMode } from "@types";
import { cloneElement, MouseEvent, PropsWithChildren, ReactElement } from "react";

interface MemberModeProtectorProps extends PropsWithChildren {
  fallbackAction?: () => void;
  requireRegister?: boolean;
}

/**
 * Allow to protect the children component based on user mode.
 * If user in OPEN mode, render a wrapper and open register drawer.
 * If user in MEMBER mode, render the children component only.
 * @param props
 * @constructor
 */
const MemberModeProtector = ({ children, fallbackAction, requireRegister = true }: MemberModeProtectorProps) => {
  const { userMode } = useAuthSession();

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // invoke fallback action if provided
    if (fallbackAction) {
      fallbackAction();
      return;
    }

    // open login drawer by default
    openOnboardingDrawer({
      mode: requireRegister ? OnboardMode.REGISTER : OnboardMode.LOGIN,
    });
  };

  /**
   * If user in OPEN mode, render a wrapper and open login drawer.
   */
  if (userMode === UserMode.GUEST) {
    return cloneElement(children as ReactElement, {
      onClick: handleOnClick,
    });
  }

  return <>{children}</>;
};

export default MemberModeProtector;
