import { STORAGE_KEY } from "@constants";
import { useCallback } from "react";

/**
 * Hook that checks if a new version of the app is available
 * Provides functions to check for new version and reload the app
 */
export const useAppUpdate = () => {
  const checkForAppUpdate = useCallback(() => {
    const appUpdateAvailable = sessionStorage.getItem(STORAGE_KEY.APP_UPDATE_AVAILABLE);
    return appUpdateAvailable === "true";
  }, []);

  const performAppReload = useCallback(() => {
    const appUpdateAvailable = checkForAppUpdate();
    if (appUpdateAvailable) {
      sessionStorage.removeItem(STORAGE_KEY.APP_UPDATE_AVAILABLE);
      window.location.reload();
    }
  }, [checkForAppUpdate]);

  return { checkForAppUpdate, performAppReload };
};
