import { Paths } from "@constants";
import useAuthSession from "@hooks/useAuthSession";
import useTypedSelector from "@hooks/useTypedSelector";
import { AuthStatus } from "@types";
import { Page, PageContent, cn } from "kz-ui-sdk";
import { PropsWithChildren, useEffect } from "react";
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";

interface PublicRouteProps extends PropsWithChildren {}

/**
 * Public route layout
 * Display content only, without header, footer, and bottom navigation
 * Public routes that do not require authentication
 * - Redirect to home page if authenticated
 */
const PublicRouteLayout = (props: PublicRouteProps) => {
  const navigate = useNavigate();
  const { status: authStatus } = useAuthSession();
  const { state, pathname } = useLocation();
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);

  useEffect(() => {
    // Skip authentication to check maintenance status
    if (matchPath(Paths.PUBLIC.MAINTENANCE, pathname)) {
      return;
    }
    // Redirect to home if authenticated
    if (authStatus === AuthStatus.Authenticated && !state?.allowPrivateAccess) {
      navigate(Paths.PRIVATE.HOME, {
        replace: true,
      });
      return;
    }
    // Redirect to reset password page if password expired
    if (authStatus === AuthStatus.PasswordExpired) {
      navigate(Paths.PRIVATE.UPDATE_PIN_FORCE, {
        replace: true,
      });
      return;
    }
  }, [navigate, authStatus, state?.allowPrivateAccess, pathname]);

  return (
    <Page className="relative mx-auto max-h-screen overflow-hidden">
      <PageContent
        className={cn({
          "pt-10": isDisplayAppInstaller,
        })}
      >
        <Outlet />
      </PageContent>
    </Page>
  );
};

export default PublicRouteLayout;
