import { cn } from "kz-ui-sdk";
import { PropsWithChildren, ReactNode, useMemo } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { SiLine } from "react-icons/si";

interface ContactButtonProps extends PropsWithChildren {
  url: string;
  className?: string;
}

const ContactButton = ({ url, className }: ContactButtonProps) => {
  const buttonStyle = useMemo(() => {
    // hardcode first in sprint 3
    return BUTTON_STYLES.line;

    // search for the button style
    // for (const [_, value] of Object.entries(BUTTON_STYLES)) {
    //   if (value.urlRegex?.test(url)) {
    //     return value;
    //   }
    // }
    //
    // return {
    //   icon: <IconNavContact className="text-content-base" />,
    //   className: "bg-grdSecondaryAlt",
    // } as ContactButtonStyle;
  }, []);

  return (
    <a
      href={url}
      className={cn(
        buttonStyle.className,
        className,
        "flex h-[30px] w-[38px] items-center justify-center rounded px-1.5",
      )}
      target={"_blank"}
      rel={"noreferrer noopener"}
    >
      {buttonStyle.icon}
    </a>
  );
};

type ContactButtonStyle = {
  icon: ReactNode;
  className: string;
  urlRegex?: RegExp;
};

const BUTTON_STYLES: Record<string, ContactButtonStyle> = {
  telegram: {
    icon: (
      <FaTelegramPlane
        color="currentColor"
        className="text-white"
        size={16}
      />
    ),
    className: "bg-[linear-gradient(180deg,#35AEE1_0%,#2096D4_100%)]",
    urlRegex: /t\.me/,
  },
  line: {
    icon: (
      <SiLine
        color="currentColor"
        className="text-white"
        size={24}
      />
    ),
    className: "bg-[radial-gradient(50%_100%_at_50%_0%,_#06C755_0%,_#06C755_100%)]",
    urlRegex: /line\.me/,
  },
};

export default ContactButton;
