import { closeDeposit } from "@components/utils/DepositDrawer/utils";
import { closeIframeDialog } from "@components/utils/IframeDialog";
import { WSDepositSuccessEvent } from "@constants/ws";
import { fAmount } from "kz-ui-sdk";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useWSDepositSuccess } from "./useAppWS";

export default function useDepositNotification() {
  const { t } = useTranslation();

  const handleDepositSuccess = useCallback(
    (event: WSDepositSuccessEvent) => {
      const fundingTx = event.detail.fundingTx;
      toast.success(t("Deposit amount completed", { amount: fAmount(fundingTx?.netAmount) }));
      closeIframeDialog({
        id: `deposit-${fundingTx?.orderRef}`,
      });
      closeDeposit();
    },
    [t],
  );

  useWSDepositSuccess({ cb: handleDepositSuccess });
}
