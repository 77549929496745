export const Paths = {
  PRIVATE: {
    HOME: "/",
    DEPOSIT: "/deposit",
    PROFILE: "/profile",
    CONTACT: "/contact",
    DAILY_REWARD: "/daily-reward",
    CASHBACK: "/cashback",
    TRANSACTION: "/transaction",
    BANK_ACCOUNTS: "/bank-accounts",
    BET_HISTORY: "/betting-history",
    LANGUAGE: "/language",
    UPDATE_BANK_FORCE: "/onboard/bank",
    UPDATE_PIN_FORCE: "/reset-pin",
    UPDATE_BANK: "/update-bank",
    UPDATE_PIN: "/update-pin",
    TERMS_CONDITIONS: "/terms-and-conditions",
    DEPOSIT_CALLBACK: "/:provider/:orderRef/return",
    REFERRAL: "/referral",
    PRIZES: "/prizes",
    REFER_BALLS: "/refer-balls",
  },
  PUBLIC: {
    ONBOARD: "/onboard/:mode",
    ONBOARD_LOGIN: "/onboard",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PIN: "/forgot-pin",
    MAINTENANCE: "/maintenance",
    REFERRAL_CALLBACK: "/r/:refCode",
  },
};
