import { siteApi } from "@api/index";
import useServerInfo from "@hooks/useServerInfo";
import useUserMode from "@hooks/useUserMode";
import { getDiff } from "@utils/reward";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function DailyReferBallClaimOnRouting() {
  const location = useLocation();
  const [claimReferral] = siteApi.useClaimReferralDailyTokensMutation();
  const { isGuest } = useUserMode();
  const { data: referralRewardStatus, isLoading: isFetchingReferralRewardStatus } =
    siteApi.useGetReferralRewardStatusQuery(undefined, {
      skip: isGuest,
    });
  const serverInfo = useServerInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledClaim = useCallback(
    throttle(
      () => {
        claimReferral();
      },
      1000,
      { leading: true, trailing: false },
    ),
    [claimReferral], // Ensure claimReferral updates when it changes
  );

  const isReferralRewardClaimable = useMemo(() => {
    if (!referralRewardStatus?.enabled) return false;
    if (isFetchingReferralRewardStatus || !referralRewardStatus?.canParticipate) return false;
    const lastClaimedAt = referralRewardStatus?.dailyFreeSetting?.lastDailyRefTokenClaimedAt;
    const currentPeriodResetDate = referralRewardStatus?.dailyFreeSetting?.currentPeriodResetDate ?? undefined;
    const nextPeriodResetDate = referralRewardStatus?.dailyFreeSetting?.nextPeriodResetDate ?? undefined;

    return (
      getDiff(
        {
          lastClaimedAt,
          currentPeriodResetDate,
          nextPeriodResetDate,
        },
        serverInfo?.utcOffset ?? 0,
      ) <= 0
    );
  }, [referralRewardStatus, serverInfo?.utcOffset, isFetchingReferralRewardStatus]);

  useEffect(() => {
    if (isReferralRewardClaimable) {
      throttledClaim();
    }
  }, [location, isReferralRewardClaimable, throttledClaim]);

  return null;
}
