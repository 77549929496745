import { FundingTxModel } from "kz-ui-sdk";

export enum WS_EVENT {
  DEPOSIT_SUCCESS = "WS_DEPOSIT_SUCCESS",
  BALANCE_CHANGED = "WS_BALANCE_CHANGED",
  TOKEN_CHANGE_EVENT = "WS_TOKEN_CHANGE_EVENT",
  BLACKLIST_UPDATE = "WS_BLACKLIST_UPDATE",
  REFERRAL_TOKEN_SETTINGS_CHANGE = "WS_REFERRAL_TOKEN_SETTINGS_CHANGE",
}

export type WSBalanceChangedEventDetail = {
  balance: string;
};

export type WSBalanceChangedEvent = CustomEvent<WSBalanceChangedEventDetail>;

export type WSDepositSuccessEventDetail = {
  fundingTx?: FundingTxModel;
};

export type WSDepositSuccessEvent = CustomEvent<WSDepositSuccessEventDetail>;

export type WSTokenChangeEventDetail = {
  tokenCount: number;
};

export type WSTokenChangeEvent = CustomEvent<WSTokenChangeEventDetail>;

export type WSBlacklistUpdateEventDetail = {
  isBlacklisted: boolean;
  timestamp: string;
};

export type WSBlacklistUpdateEvent = CustomEvent<WSBlacklistUpdateEventDetail>;

export type WSReferralTokenSettingsChangeEventDetail = {};

export type WSReferralTokenSettingsChangeEvent = CustomEvent<WSReferralTokenSettingsChangeEventDetail>;
